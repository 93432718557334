<template>
  <div>
    <fieldset class="slider">
      <label>
        {{ selectedValue }}
        <input
          :id="questionKey"
          type="range"
          min="0"
          max="100"
          :value="selectedValue ? selectedValue : 0"
          aria-valuemin="0"
          aria-valuemax="100"
          :step="step"
          :aria-valuenow="selectedValue ? selectedValue : 0"
          @change="updateSlider"
        >
        <div class="label-wrapper">
          <span class="label label-min">{{ min }}</span>
          <span class="label label-max">{{ max }}</span>
        </div>
      </label>
    </fieldset>
  </div>
</template>

<script>
  export default {
    name: 'SliderField',

    props: {
      min: {
        type: String,
        required: true
      },
      max: {
        type: String,
        required: true
      },
      step: {
        type: Number,
        default: 1
      },
      questionKey: {
        type: String,
        required: true
      },
      selectedValue: {
        type: [String, Number],
        default: 0
      }
    },

    methods: {
      updateSlider($event) {
        this.$emit('changed', $event.target.value);
      }
    }
  };
</script>
