<template>
  <nav class="container nav-container">
    <localized-link
      to="/"
      :aria-label="$t('navigation.home')"
    >
      <img
        v-if="$i18n.locale === 'en'"
        src="/img/DEN-logo-en.svg"
        alt="logo DEN"
        width="245"
        height="54"
      >
      <img
        v-else
        src="/img/DEN-logo.svg"
        alt="logo DEN"
        width="245"
        height="54"
      >
    </localized-link>
    <language-switcher v-slot="{ links }">
      <router-link
        v-for="link in links"
        :key="link.langIndex"
        :to="`/${link.langIndex}`"
        :class="link.activeClass"
      >
        <span>{{ link.langName }}</span>
      </router-link>
    </language-switcher>
  </nav>
</template>

<script>
  export default {
    name: 'MainNavigation'
  };
</script>
