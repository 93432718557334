<template>
  <div
    v-if="showCookieBanner"
    class="cookie-banner"
  >
    <div class="cookie-banner-text">
      <i18n
        path="cookie.mainText"
        tag="div"
      >
        <template #terms>
          <a
            href="https://www.den.nl/over-ons/gebruikersvoorwaarden"
            target="_blank"
            rel="noopener"
          >{{ $t('cookie.terms') }}</a>
        </template>
        <template #cookies>
          <a
            href="https://www.den.nl/over-ons/cookies"
            target="_blank"
            rel="noopener"
          >{{ $t('cookie.cookies') }}</a>
        </template>
        <template #privacy>
          <a
            href="https://www.den.nl/privacy"
            target="_blank"
            rel="noopener"
          >{{ $t('cookie.privacy') }}</a>
        </template>
      </i18n>
    </div>
    <div class="cookie-banner-buttons">
      <button
        class="btn btn-link"
        @click="declineCookies(false)"
      >
        {{ $t('cookie.buttonDecline') }}
      </button>
      <button
        class="btn"
        @click="acceptCookies(false)"
      >
        {{ $t('cookie.buttonAllow') }}
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CookieBanner',

    data() {
      return {
        showCookieBanner: false
      };
    },

    computed: {
      cookieExpirationDate() {
        const d = new Date();
        d.setTime(d.getTime() + (30 * 24 * 60 * 60 * 1000));
        return 'expires=' + d.toUTCString();
      }
    },

    created() {
      const cookie = document.cookie.split('; ').find(row => row.startsWith('DENFconsent='));
      if (cookie) {
        if (cookie.split('=')[1] === 'accepted') {
          this.acceptCookies(true);
        } else if (cookie.split('=')[1] === 'declined') {
          this.declineCookies(true);
        }
      } else {
        this.showCookieBanner = true;
      }
    },

    methods: {
      declineCookies(cookieIsSet) {
        this.$gtag.optOut();
        this.showCookieBanner = false;
        if (!cookieIsSet) {
          document.cookie = `DENFconsent=declined;${this.cookieExpirationDate}`;
        }
      },
      acceptCookies(cookieIsSet) {
        this.$gtag.optIn();
        this.showCookieBanner = false;
        if (!cookieIsSet) {
          document.cookie = `DENFconsent=accepted;${this.cookieExpirationDate}`;
        }
      }
    }
  };
</script>
