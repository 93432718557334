<template>
  <div
    class="row result result-organisation"
  >
    <div
      class="column text-column"
    >
      <h2>{{ $t('result.organisationTitle') }}</h2>
      <p
        v-for="question in organisationFormData"
        :key="question.question"
      >
        <strong>{{ question.questionText }}</strong><br>
        <span>
          {{ getAnswer(question.answer) }}
        </span>
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    name: 'ResultOrganisation',

    computed: {
      ...mapState(['organisationFormData'])
    },

    methods: {
      getAnswer(answer) {
        if (!answer) return '-';
        if (answer.radio) {
          if (answer.other && answer.other !== null) {
            return `${answer.radio.value} ${answer.other}`;
          }
          return answer.radio.value;
        } else if (answer.checkbox) {
          let checked = [];
          for (const c of answer.checkbox) {
            if (c.key === 'other' && answer.other !== null) {
              checked.push(`${c.value} ${answer.other}`);
            } else {
              checked.push(c.value);
            }
          }
          return checked.join(', ');
        }
        return answer;
      }
    }
  };
</script>
