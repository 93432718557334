<template>
  <div id="app">
    <MainNavigation />
    <main>
      <router-view :key="$route.path" />
    </main>
    <PageFooter />
    <CookieBanner />
  </div>
</template>

<script>
  import MainNavigation from '@/components/MainNavigation.vue';
  import PageFooter from '@/components/PageFooter.vue';
  import CookieBanner from '@/components/CookieBanner.vue';

  export default {
    components: {
      MainNavigation,
      PageFooter,
      CookieBanner
    },

    metaInfo() {
      return {
        title: 'Focusmodel',
        titleTemplate: '%s | Focusmodel | DEN',
        htmlAttrs: {
          lang: this.$i18n.locale ? this.$i18n.locale : 'nl'
        },
        meta: [
          { name: 'description', content: this.$t('seo.description') }
        ]
      };
    }
  };
</script>

<style lang="scss">
@import "~normalize.css/normalize.css";
@import "./design";
</style>
