<template>
  <div class="quadrant">
    <div
      class="quadrant-grid"
    >
      <span class="label label-top-left">{{ $t('quadrant.labels.expression') }}</span>
      <span class="label label-top-right">{{ $t('quadrant.labels.experience') }}</span>
      <span class="label label-bottom-right">{{ $t('quadrant.labels.marketing') }}</span>
      <span class="label label-bottom-left">{{ $t('quadrant.labels.process') }}</span>
      <span class="circle" />
    </div>
    <template
      v-if="selected"
    >
      <div
        v-for="(key, index) in selected"
        :key="index"
        class="quadrant-grid-scoped"
        :class="`quadrant-grid-${key}`"
      >
        <svg>
          <polygon
            class="shape"
            :points="clipPath[index]"
            :class="{ 'shape-active': activeProject === index}"
          />
        </svg>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'FocusQuadrant',

    props: {
      coordinates: {
        type: Array,
        default: null
      },
      selected: {
        type: Array,
        default: null
      },
      activeProject: {
        type: Number,
        default: 0
      }
    },

    computed: {
      clipPath() {
        // TODO: refactor code
        const allPaths = [];
        for (const projectCoordinates of this.coordinates) {
          let path = '';
          for (let c = 0; c < projectCoordinates.length; c++) {
            if (projectCoordinates[c]) {
              path += `${this.getPixelSize(projectCoordinates[c][0])},${this.getPixelSize(projectCoordinates[c][1])} `;
            }

            if (c === 1 && !projectCoordinates[c]) {
              path += `${this.getPixelSize(5)},0 `;
            } else if (c === 2 && !projectCoordinates[c]) {
              path += `${this.getPixelSize(5)},${this.getPixelSize(5)} `;
            } else if (c === 3 && !projectCoordinates[c]) {
              path += `0,${this.getPixelSize(5)} `;
            }
          }
          allPaths.push(path.slice(0, -1));
        }
        return allPaths;
      }
    },

    methods: {
      getPixelSize(perc) {
        const grid = 225; // make this responsive?
        return grid * perc / 100;
      }
    }
  };
</script>
