<template>
  <div>
    <fieldset class="checkbox">
      <label
        v-for="option in options"
        :key="option.key"
      >
        <input
          v-model="checkedValues"
          type="checkbox"
          :name="questionKey"
          :value="option.key"
          @change="updateParent()"
        >
        {{ option.value }}
        <input
          v-if="option.key === 'other'"
          v-model="otherValue"
          type="text"
          :placeholder="$t('form.other')"
          :name="`${option.key}-text`"
          @focus="selectCheckboxField()"
          @blur="updateParent()"
        >
      </label>
    </fieldset>
  </div>
</template>

<script>
  export default {
    name: 'CheckboxField',

    props: {
      options: {
        type: Array,
        required: true
      },
      questionKey: {
        type: String,
        required: true
      },
      selectedValue: {
        type: [String, Object],
        default: null
      }
    },

    data() {
      return {
        checkedValues: this.selectedValue ? this.selectedKeys(this.selectedValue) : [],
        otherValue: this.selectedValue ? this.selectedValue.other : null
      };
    },

    watch: {
      questionKey() {
        this.checkedValues = [];
        this.otherValue = null;
      }
    },

    methods: {
      updateParent() {
        const otherInChecked = this.checkedValues.includes('other');
        if (!otherInChecked) {
          this.otherValue = null;
        }
        const checked = this.options.filter(({ key }) => this.checkedValues.includes(key));
        this.$emit('changed', { 'checkbox': checked, 'other': this.otherValue });
      },

      selectedKeys(value) {
        const selected = [];
        for (const checkbox of value.checkbox) {
          selected.push(checkbox.key);
        }
        return selected;
      },

      selectCheckboxField() {
        this.checkedValues.push('other');
      }
    }
  };
</script>
