<template>
  <div class="container">
    <template
      v-if="currentPage"
    >
      <div class="row">
        <div class="column text-column">
          <h1>{{ currentPage.title }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="column text-column">
          <ParagraphBlock
            :content="currentPage.content"
          />
          <div class="buttons">
            <localized-link
              to="/intro"
              tag="button"
              class="btn"
            >
              {{ $t('button.intro') }}
            </localized-link>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import ParagraphBlock from '@/components/ParagraphBlock.vue';

  export default {
    components: {
      ParagraphBlock
    },

    computed: {
      currentPage() {
        if (!this.pageContent) return;
        return this.pageContent['home'];
      },

      ...mapState(['pageContent'])
    },

    created() {
      // reset model
      this.$store.dispatch('resetModel');
      // get content
      this.$store.dispatch('getContent', this.$i18n.locale);
    },

    metaInfo: {
      title: 'Focusmodel | DEN',
      titleTemplate: null
    }
  };
</script>
