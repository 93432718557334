<template>
  <div>
    <fieldset class="text">
      <textarea
        v-if="type === 'textarea'"
        :value="selectedValue"
        rows="7"
        :name="questionKey"
        :placeholder="placeholder"
        @blur="updateParent($event.target.value)"
        @input="updateParent($event.target.value)"
      />
      <input
        v-else
        :type="type"
        :value="selectedValue"
        :name="questionKey"
        :placeholder="placeholder"
        :min="type === 'number' ? 0 : false"
        @blur="updateParent($event.target.value)"
        @input="updateParent($event.target.value)"
      >
    </fieldset>
  </div>
</template>

<script>
  export default {
    name: 'TextField',

    props: {
      questionKey: {
        type: String,
        required: true
      },
      type: {
        type: String,
        default: 'text'
      },
      placeholderText: {
        type: String,
        required: true
      },
      selectedValue: {
        type: String,
        default: null
      },
      isRequired: {
        type: Boolean,
        default: false
      }
    },

    computed: {
      placeholder() {
        if (this.isRequired) {
          return `${this.placeholderText} (${this.$t('form.required') })`;
        }
        return this.placeholderText;
      }
    },

    methods: {
      updateParent(value) {
        this.$emit('changed', value);
      }
    }
  };
</script>
