<template>
  <div class="paragraph-block">
    <h3 v-if="title">
      {{ title }}
    </h3>
    <!-- eslint-disable vue/no-v-html -->
    <template v-if="typeof content === 'string'">
      <p
        v-html="content"
      />
    </template>
    <template v-else>
      <p
        v-for="(paragraph, index) in content"
        :key="index"
        v-html="paragraph"
      />
    </template>
    <!-- eslint-disable vue/no-v-html -->
  </div>
</template>

<script>
  export default {
    name: 'ParagraphBlock',

    props: {
      title: {
        type: [String, Boolean],
        default: null
      },
      content: {
        type: [String, Array],
        default: null
      }
    }
  };
</script>
