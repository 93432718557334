<template>
  <div>
    <draggable
      v-model="sortables"
      :group="questionKey"
      class="sortable"
      @start="drag=true"
      @end="drag=false"
      @change="updateParent"
    >
      <div
        v-for="option in sortables"
        :key="option.key"
      >
        <span class="draganddrop">
          <span />
          <span />
          <span />
        </span>
        {{ option.value }}
      </div>
    </draggable>
  </div>
</template>

<script>
  import draggable from 'vuedraggable';

  export default {
    name: 'SortableField',

    components: {
      draggable
    },

    props: {
      options: {
        type: Array,
        required: true
      },
      questionKey: {
        type: String,
        required: true
      },
      selectedValue: {
        type: [String, Array],
        default: () => []
      }
    },

    data() {
      return {
        sortables: this.selectedValue ? this.selectedValue : this.options
      };
    },

    created() {
      this.$emit('changed', { 'sort': this.sortables });
    },

    methods: {
      updateParent() {
        this.$emit('changed', { 'sort': this.sortables });
      }
    }
  };
</script>
