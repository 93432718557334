<template>
  <div class="container">
    <template
      v-if="currentPage"
    >
      <div class="row">
        <div class="column text-column">
          <h1>{{ currentPage.title }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="column text-column">
          <ParagraphBlock
            :content="currentPage.content"
          />
          <div class="buttons buttons-column">
            <a
              class="btn btn-outline"
              :href="emailDEN"
            >
              {{ $t('button.contactDEN') }}
            </a>
            <localized-link
              to="/organisation/0"
              tag="button"
              class="btn"
            >
              {{ $t('button.start') }}
            </localized-link>
          </div>
        </div>
        <div
          v-if="currentPage.image"
          class="column image-column"
        >
          <a
            :href="`../../${currentPage.image}`"
            target="_blank"
            rel="noopener"
          >
            <img
              :src="`../../${currentPage.image}`"
              alt=""
            >
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex';

  import ParagraphBlock from '@/components/ParagraphBlock.vue';

  export default {
    components: {
      ParagraphBlock
    },

    computed: {
      currentPage() {
        if (!this.pageContent) return;
        return this.pageContent['intro'];
      },

      emailDEN() {
        return `mailto:den@den.nl?subject=${this.$t('contact.subject')}`;
      },

      ...mapState(['pageContent'])
    },

    created() {
      if (this.pageContent.length === 0) {
        this.$store.dispatch('resetModel');
        this.$store.dispatch('getContent', this.$i18n.locale);
      }
    },

    metaInfo() {
      return {
        title: this.$t('seo.title.intro')
      };
    }
  };
</script>
