<template>
  <div>
    <fieldset class="radio">
      <label
        v-for="option in options"
        :key="option.key"
      >
        <input
          v-model="radioValue"
          type="radio"
          :name="questionKey"
          :value="option.key"
          @change="updateParent()"
        >
        {{ option.value }}
        <input
          v-if="option.key === 'other'"
          v-model="otherValue"
          type="text"
          :placeholder="$t('form.other')"
          :name="`${option.key}-text`"
          @focus="selectRadioField(option.key)"
          @blur="updateParent()"
        >
        <input
          v-if="option.key === 'more'"
          v-model="moreValue"
          type="text"
          :placeholder="$t('form.more')"
          :name="`${option.key}-text`"
          @focus="selectRadioField(option.key)"
          @blur="updateParent()"
        >
      </label>
    </fieldset>
  </div>
</template>

<script>
  export default {
    name: 'RadioField',

    props: {
      options: {
        type: Array,
        required: true
      },
      questionKey: {
        type: String,
        required: true
      },
      selectedValue: {
        type: [String, Object],
        default: null
      }
    },

    data() {
      return {
        moreValue: this.selectedValue ? this.selectedValue.more : null,
        otherValue: this.selectedValue ? this.selectedValue.other : null,
        radioValue: this.selectedValue ? this.selectedValue.radio.key : null
      };
    },

    watch: {
      questionKey() {
        this.radioValue = null;
        this.otherValue = null;
        this.moreValue = null;
      }
    },

    methods: {
      updateParent() {
        if (this.radioValue !== 'other') {
          this.otherValue = null;
        }

        if (this.radioValue !== 'more') {
          this.moreValue = null;
        }

        const radio = this.options.find(({ key }) => key === this.radioValue);
        this.$emit('changed', { radio, 'other': this.otherValue, 'more': this.moreValue });
      },

      selectRadioField(key) {
        this.radioValue = key === 'more' ? 'more' : 'other';
      }
    }
  };
</script>
