<template>
  <div>
    <!-- eslint-disable vue/no-v-html -->
    <p
      v-for="(paragraph, index) in intro"
      :key="index"
      v-html="paragraph"
    />
    <!-- eslint-disable vue/no-v-html -->
  </div>
</template>

<script>
  export default {
    name: 'IntroSection',

    props: {
      intro: {
        type: Array,
        required: true
      },
      questionKey: {
        type: String,
        required: true
      }
    }
  };
</script>
