import Vue from 'vue';
import LangRouter from 'vue-lang-router';

import Home from '../views/Home.vue';
import Intro from '../views/Intro.vue';
import NotFound from '../views/NotFound.vue';
import Form from '../views/Form.vue';
import Result from '../views/Result.vue';

import translations from '../design/lang';

Vue.use(LangRouter, {
  defaultLanguage: 'nl',
  translations
});

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/intro',
    name: 'intro',
    component: Intro
  },
  {
    path: '/project/:project?/:question?',
    name: 'project',
    component: Form
  },
  {
    path: '/projectextra/:question?',
    name: 'projectExtra',
    component: Form
  },
  {
    path: '/organisation/:question?',
    name: 'organisation',
    component: Form
  },
  {
    path: '/strategy/:question?',
    name: 'strategy',
    component: Form
  },
  {
    path: '/result',
    name: 'result',
    component: Result
  },
  {
    path: '*',
    name: 'notfound',
    component: NotFound
  }
];

const router = new LangRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
