<template>
  <div class="link-block">
    <h3 v-if="title">
      {{ title }}
    </h3>
    <ul v-if="links.length > 0">
      <li
        v-for="(link, index) in links"
        :key="index"
      >
        <a
          :href="link.link"
          target="_blank"
        >
          {{ link.linkText }}
        </a><br>
        <span class="print-pdf">[ {{ link.link }} ]</span>
      </li>
    </ul>
  </div>
</template>

<script>
  export default {
    name: 'ParagraphBlock',

    props: {
      title: {
        type: String,
        default: null
      },
      links: {
        type: [String, Array],
        default: null
      }
    }
  };
</script>
