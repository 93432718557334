import Vuex from 'vuex';
import Vue from 'vue';
import axios from 'axios';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    baseUrl: process.env.BASE_URL,
    pageContent: [],
    currentProject: 0,
    currentForm: null,
    selectedQuadrant: null,
    projectFormData: [],
    projectExtraFormData: [],
    strategyFormData: [],
    strategyTotalScore: 0,
    organisationFormData: [],
    currentFormData: [],
    adviceStrategy: false,
    adviceProjects: false
  },

  mutations: {
    resetModel(state) {
      state.strategyTotalScore = 0;
      state.pageContent = [];
      state.currentProject = 0;
      state.selectedQuadrant = null;
      state.projectFormData = [];
      state.projectExtraFormData = [];
      state.strategyFormData = [];
      state.organisationFormData = [];
      state.currentFormData = [];
      state.currentForm = null;
      state.adviceStrategy = false;
      state.adviceProjects = false;
    },

    updateContent(state, data) {
      state.pageContent = data;
    },

    createProject(state) {
      state.currentProject += 1;
      state.selectedQuadrant = null;
      state.currentFormData = [];
    },

    setQuadrant(state, data) {
      state.selectedQuadrant = data;
    },

    setCurrentForm(state, data) {
      if (state.currentForm !== data) {
        state.currentFormData = [];
      }
      state.currentForm = data;
    },

    saveProjectQuestion(state, data) {
      if (!state.projectFormData[data.project]) {
        state.projectFormData[data.project] = [];
      }

      // add or update question
      state.projectFormData[data.project][data.question] = {
        'question': data.question,
        'questionKey': data.questionKey,
        'questionText': data.questionText,
        'answer': data.answer
      };

      state.currentFormData = state.projectFormData[data.project];
    },

    saveProjectExtraQuestion(state, data) {
      // add or update question
      state.projectExtraFormData[data.question] = {
        'question': data.question,
        'questionKey': data.questionKey,
        'questionText': data.questionText,
        'answer': data.answer
      };

      state.currentFormData = state.projectExtraFormData;
    },

    saveStrategyQuestion(state, data) {
      // add or update question
      state.strategyFormData[data.question] = {
        'question': data.question,
        'questionKey': data.questionKey,
        'questionWeight': data.questionWeight,
        'questionRecalculateWeight': data.questionRecalculateWeight,
        'questionText': data.questionText,
        'answer': data.answer
      };

      state.currentFormData = state.strategyFormData;
    },

    updateTotalScore(state) {
      let tempScore = 0;
      state.strategyFormData.forEach(question => {
        const answer = question.answer;
        if (answer) {
          if (answer.radio) {
            tempScore += question.questionWeight * answer.radio.weight;
          } else if (answer.checkbox) {
            let score = 0;
            for (const k of answer.checkbox) {
              score += question.questionWeight * k.weight;
            }
            tempScore += score;
          } else {
            if (question.questionKey === 'strategySupport' || question.questionKey === 'strategyNotice') {
              tempScore += Math.floor(answer / (question.questionRecalculateWeight * 2));
            }
            tempScore += 0;
          }
        }
      });
      state.strategyTotalScore = tempScore;
    },

    saveOrganisationQuestion(state, data) {
      // add or update question
      state.organisationFormData[data.question] = {
        'question': data.question,
        'questionKey': data.questionKey,
        'questionText': data.questionText,
        'answer': data.answer
      };

      state.currentFormData = state.organisationFormData;
    },

    hasShownAdviceStrategy(state) {
      state.adviceStrategy = true;
    },

    hasShownAdviceProjects(state) {
      state.adviceProjects = true;
    }
  },

  actions: {
    resetModel(context) {
      context.commit('resetModel');
    },

    getContent(context, locale) {
      let dataFile = locale === 'en' ? 'content-en.json' : 'content.json';

      if (Object.keys(context.state.pageContent).length !== 0) return;
      return axios
        .get(context.state.baseUrl + dataFile)
        .then(response => {
          context.commit('updateContent', response.data.pages);
        });
    },

    saveQuestion(context, data) {
      if (data.type === 'organisation') {
        context.commit('saveOrganisationQuestion', data);
      } else if (data.type === 'project') {
        context.commit('saveProjectQuestion', data);
      } else if (data.type === 'strategy') {
        context.commit('saveStrategyQuestion', data);
        context.commit('updateTotalScore');
      } else if (data.type === 'projectExtra') {
        context.commit('saveProjectExtraQuestion', data);
      }
    }
  }
});
