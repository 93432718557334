export default {
  en: {
    name: 'EN',
    load: () => {
      return import('./en.json');
    }
  },
  nl: {
    name: 'NL',
    load: () => {
      return import('./nl.json');
    }
  }
};
