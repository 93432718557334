<template>
  <div
    class="container"
  >
    <template
      v-if="currentPage"
    >
      <div class="row">
        <div class="column text-column">
          <h1>{{ currentPage.title }}</h1>
        </div>
      </div>
      <div class="row">
        <div class="column text-column">
          <div
            v-if="showIntro"
          >
            <ParagraphBlock
              :content="currentPage.content"
            />
          </div>
          <div v-else-if="introNextSection">
            <ParagraphBlock
              :content="nextSectionText"
            />
          </div>
          <div v-else>
            <span class="navigation-label">
              {{ $t(`navigation.${currentForm}`) }} |
              <template v-if="currentForm === 'project'">
                {{ $t('navigation.projectNo') }} #{{ currentProject + 1 }} |
              </template>
              {{ $t('navigation.question') }} {{ currentQuestion + 1 }} / {{ totalNumberQuestions }}
            </span>
            <h2 v-if="questionDetails.type === 'slider' && sliderQuestion(questionDetails.key)">
              {{ sliderQuestion(questionDetails.key) }}
              <span v-if="isRequired">*</span>
            </h2>
            <h2 v-else>
              {{ questionDetails.question }}
              <span v-if="isRequired">*</span>
            </h2>
            <ParagraphBlock
              :content="questionDetails.help"
            />
            <keep-alive>
              <component
                :is="currentComponent"
                :type="questionDetails.type"
                :question-key="questionDetails.key"
                :placeholder-text="questionDetails.placeholder"
                :selected-value="currentAnswerDetails"
                :options="questionDetails.options"
                :min="sliderQuestionMin()"
                :max="sliderQuestionMax(questionDetails.key)"
                :step="questionDetails.step"
                :intro="questionDetails.intro"
                :is-required="isRequired"
                @changed="handleInput"
              />
            </keep-alive>
          </div>
          <div class="buttons">
            <button
              v-if="lastQuestion && currentForm === 'project' && !introNextSection"
              class="btn btn-secondary btn-small btn-margin"
              @click="addProject"
            >
              {{ $t('button.addProject') }}
            </button>
          </div>
          <div
            class="buttons"
            :class="{ 'buttons-column': introNextSection }"
          >
            <template
              v-if="introNextSection"
            >
              <button
                v-if="currentForm !== 'strategy' && strategyFormData.length === 0"
                class="btn"
                @click="nextSection('strategy')"
              >
                {{ $t('button.strategy') }}
              </button>
              <button
                v-if="currentForm !== 'project' && (!projectFormData || projectFormData.length === 0)"
                class="btn"
                @click="nextSection('project')"
              >
                {{ $t('button.project') }}
              </button>
              <button
                v-if="currentForm !== 'organisation'"
                class="btn"
                @click="nextSection('result')"
              >
                {{ $t('button.result') }}
              </button>
            </template>
            <template
              v-else
            >
              <button
                v-if="currentQuestion > 0"
                class="btn btn-outline"
                @click="prevQuestion($event)"
              >
                {{ $t('button.previous') }}
              </button>
              <button
                class="btn"
                :class="{ disabled: isRequired && !currentQuestionIsValid && !showIntro }"
                :disabled="isRequired && !currentQuestionIsValid && !showIntro"
                @click="nextQuestion($event)"
              >
                {{ $t('button.next') }}
              </button>
            </template>
          </div>
        </div>
        <div
          v-if="!showIntro"
          class="column image-column"
        >
          <a
            v-if="questionDetails.image"
            :href="`../../../${questionDetails.image}`"
            target="_blank"
            rel="noopener"
          >
            <img
              :src="`../../../${questionDetails.image}`"
              alt=""
            >
          </a>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ParagraphBlock from '@/components/ParagraphBlock.vue';
  import RadioField from '@/components/RadioField.vue';
  import CheckboxField from '@/components/CheckboxField.vue';
  import TextField from '@/components/TextField.vue';
  import SliderField from '@/components/SliderField.vue';
  import SortableField from '@/components/SortableField.vue';
  import IntroSection from '@/components/IntroSection.vue';

  export default {
    components: {
      ParagraphBlock,
      RadioField,
      CheckboxField,
      TextField,
      SliderField,
      SortableField,
      IntroSection
    },

    data() {
      return {
        showIntro: true,
        currentQuestion: 0,
        currentQuestionIsValid: false,
        tempAnswer: null,
        introNextSection: false
      };
    },

    computed: {
      currentPage() {
        if (!this.pageContent) return;
        return this.pageContent[this.currentForm];
      },

      questionDetails() {
        if (!this.currentPage.questions) return null;
        return this.currentPage.questions[this.currentQuestion];
      },

      nextSectionText() {
        if ((this.currentForm === 'project' || this.currentForm === 'projectExtra') && this.strategyFormData.length > 0) {
          return this.currentPage.nextSection.slice(0, 1);
        } else if (this.currentForm === 'strategy' && this.projectFormData.length > 0) {
          return this.currentPage.nextSection.slice(0, 1);
        }
        return this.currentPage.nextSection;
      },

      currentComponent() {
        if (this.questionDetails.type === 'radio') {
          return RadioField;
        } else if (this.questionDetails.type === 'checkbox') {
          return CheckboxField;
        } else if (this.questionDetails.type === 'slider') {
          return SliderField;
        } else if (this.questionDetails.type === 'sort') {
          return SortableField;
        } else if (this.questionDetails.type === 'intro') {
          return IntroSection;
        } else {
          return TextField;
        }
      },

      currentAnswerDetails() {
        if (!this.currentFormData) return '';
        if (!this.currentFormData[this.currentQuestion]) return '';
        this.validateQuestion(this.currentFormData[this.currentQuestion]);

        // format in case of coordinates
        const coordinateSliders = ['slider1', 'slider2', 'slider3'];
        const answer = this.currentFormData[this.currentQuestion].answer;
        if (!answer) return '';

        if (this.questionDetails.key, coordinateSliders.includes(this.questionDetails.key)) {
          if (this.questionDetails.key === 'slider3') {
            return answer[1];
          }
          return answer[0];
        }

        if (this.questionDetails.key === 'projectFocusQuadrant') {
          return answer.sort;
        }

        return answer;
      },

      isRequired() {
        return this.questionDetails.required;
      },

      totalNumberQuestions() {
        return this.currentPage.questions.length;
      },

      lastQuestion() {
        return this.currentQuestion + 1 === this.totalNumberQuestions;
      },

      linkedQuadrants() {
        return {
          'expression': ['experience', 'marketing', 'process'],
          'experience': ['expression', 'process', 'marketing'],
          'marketing': ['process', 'expression', 'experience'],
          'process': ['marketing', 'experience', 'expression']
        };
      },

      minMaxOptionsQuadrants() {
        return {
          'expression': this.$t('quadrant.slidersMinMax.expression'),
          'experience': this.$t('quadrant.slidersMinMax.experience'),
          'marketing': this.$t('quadrant.slidersMinMax.marketing'),
          'process': this.$t('quadrant.slidersMinMax.process')
        };
      },

      currentSectionPart() {
        if (this.currentForm === 'project') {
          if (this.$route.params.project === 'intro' || this.$route.params.project === undefined) {
            return 'intro';
          } else if (this.$route.params.project === 'next') {
            return 'next';
          }
          return null;
        } else {
          if (this.$route.params.question === 'intro' || this.$route.params.question === undefined) {
            return 'intro';
          } else if (this.$route.params.question === 'next') {
            return 'next';
          }
          return null;
        }
      },

      ...mapState(['pageContent', 'currentProject', 'currentForm', 'selectedQuadrant', 'currentFormData', 'strategyFormData', 'projectFormData', 'projectExtraFormData'])
    },

    mounted() {
      this.showIntro = false;

      // if part is intro
      if (this.currentSectionPart === 'intro') {
        // in case of no intro, redirect to first question
        if (this.currentForm === 'organisation') {
          this.$router.push({ params: { question: 0 } }).catch(() => {});
        }
        this.showIntro = true;
      // if project is next
      } else if (this.currentSectionPart === 'next') {
        if (this.currentFormData.length === 0) {
          this.redirectQuestion();
        }
        this.introNextSection = true;
      } else {
        if (this.$route.params.question > this.currentFormData.length) {
          this.redirectQuestion();
        }

        let questionNo = Number(this.$route.params.question || 0);
        this.currentQuestion = isNaN(questionNo) ? 0 : questionNo;
      }
    },

    created() {
      if (this.pageContent.length === 0) {
        this.$store.dispatch('resetModel');
        this.$store.dispatch('getContent', this.$i18n.locale);
      }

      this.$store.commit('setCurrentForm', this.$route.name);

      if (this.currentForm === 'organisation') {
        this.showIntro = false;
      } else if (this.currentForm === 'projectExtra' && this.projectFormData.length === 0) {
        // redirect projects => todo redirect home?
        this.$router.push({ name: 'project' }).catch(() => {});
      }
    },

    metaInfo() {
      return {
        title: this.$t(`navigation.${this.currentForm}`)
      };
    },

    methods: {
      redirectQuestion() {
        let params = {};
        if (this.currentForm === 'project') {
          params.project = 'intro';
          params.question = null;
        } else {
          params.question = 'intro';
        }

        this.$router.push({ params }).catch(() => {});
      },

      prevQuestion(event) {
        this.saveFormField();
        this.$router.push({ params: { question: this.currentQuestion - 1 } }).catch(() => {});
        this.$nextTick(() => event.target.blur());
      },

      nextQuestion(event) {
        let params = {};

        if (this.showIntro) {
          // intro
          this.showIntro = false;
          params.question = 0;
          if (this.currentForm === 'project') {
            params.project = this.currentProject;
          }
        } else if (this.currentQuestion + 1 < this.totalNumberQuestions) {
          // questions
          this.saveFormField();
          params.question = this.currentQuestion + 1;
          if (this.currentForm === 'project') {
            params.project = this.currentProject;
          }
        } else {
          // next in-between screen
          this.saveFormField();
          if (this.currentForm === 'project' && this.projectExtraFormData.length === 0) {
            this.nextSection('projectExtra');
            return;
          } else if (this.currentForm === 'project' && this.projectExtraFormData.length > 0) {
            params.project = 'next';
            params.question = null;
          } else {
            params.question = 'next';
          }
        }

        this.$router.push({ params }).catch(() => {});
        this.$nextTick(() => event.target.blur());
      },

      nextSection(section) {
        this.$store.commit('setQuadrant', null); // clear quadrant
        this.introNextSection = false;

        if (section === 'projectExtra') {
          this.$router.push({ name: section, params: { question: '0' } }).catch(() => {});
        } else {
          this.$router.push({ name: section, params: { question: 'intro' } }).catch(() => {});
        }
      },

      findQuadrant() {
        const quadrant = this.projectFormData[this.currentProject].find(question => question.questionKey === 'quadrant');
        if (quadrant) {
          return quadrant;
        }
        return false;
      },

      sliderQuestion(key) {
        if (this.currentForm !== 'project') return false;

        if (!this.selectedQuadrant) {
          const quadrant = this.findQuadrant();
          if (this.projectFormData[this.currentProject].length > 0 && quadrant) { // perhaps in project history
            this.$store.commit('setQuadrant', quadrant.answer.radio.key);
          } else {
            return false;
          }
        }

        const question0 = this.$t('quadrant.slidersQuestions.question0');
        const question1 = this.$t('quadrant.slidersQuestions.question1');
        const question2 = this.$t('quadrant.slidersQuestions.question2');
        const question3 = question1[this.linkedQuadrants[this.selectedQuadrant][Number(key.replace('slider', '')) - 1]];
        return `${question0} ${question1[this.selectedQuadrant]}. ${question2} ${question3}?`;
      },

      sliderQuestionMin() {
        if (this.currentForm !== 'project') return this.questionDetails.min;
        if (!this.selectedQuadrant) return this.questionDetails.min;
        return this.minMaxOptionsQuadrants[this.selectedQuadrant];
      },

      sliderQuestionMax(key) {
        if (this.currentForm !== 'project') return this.questionDetails.max;
        if (!this.selectedQuadrant) return this.questionDetails.max;
        return this.minMaxOptionsQuadrants[this.linkedQuadrants[this.selectedQuadrant][Number(key.replace('slider', '')) - 1]];
      },

      handleInput(value) {
        if (this.questionDetails.type === 'slider') {
          this.handleSlider(value);
        } else if (this.questionDetails.type === 'radio') {
          this.handleRadio(value);
        } else {
          this.updateCurrentAnswer(value);
        }
      },

      handleRadio(value) {
        const questionKey = this.questionDetails.key;
        if (questionKey === 'quadrant') {
          this.$store.commit('setQuadrant', value.radio.key);
        }
        this.updateCurrentAnswer(value);
      },

      handleSlider(value) {
        if (this.selectedQuadrant) {
          const questionKey = this.questionDetails.key;
          const coordinates = [];
          const sliderValue = value ? value : 5;

          coordinates.push(parseInt(sliderValue));
          coordinates.push(parseInt(sliderValue));

          if (questionKey === 'slider1') { // horizontal, x, y = 0
            coordinates[1] = 0;
          } else if (questionKey === 'slider3') { // vertical, y, x = 0
            coordinates[0] = 0;
          }

          this.updateCurrentAnswer(coordinates);
        } else {
          this.updateCurrentAnswer(value ? value : 0);
        }
      },

      validateQuestion(value) {
        if ((this.questionDetails.required && value) || !this.questionDetails.required) {
          this.currentQuestionIsValid = true;
        } else {
          this.currentQuestionIsValid = false;
        }
      },

      updateCurrentAnswer(value) {
        if (value) {
          this.tempAnswer = value;
        }
        this.validateQuestion(value);
      },

      addProject() {
        this.saveFormField();
        this.$store.commit('createProject');
        this.currentQuestion = 0;
        this.tempAnswer = null;
        this.$router.push({ params: { question: 0, project: this.currentProject } }).catch(() => {});
      },

      saveFormField() {
        if (!this.tempAnswer) {
          // look in stored data
          if (this.currentFormData && this.currentFormData[this.currentQuestion]) {
            this.tempAnswer = this.currentFormData[this.currentQuestion].answer;
          }
        }

        this.$store.dispatch('saveQuestion', {
          type: this.currentForm,
          project: this.currentProject,
          question: this.currentQuestion,
          questionKey: this.questionDetails.key,
          questionWeight: this.questionDetails.weight,
          questionRecalculateWeight: this.questionDetails.step,
          questionText: this.questionDetails.question,
          answer: this.tempAnswer
        });
        this.currentQuestionIsValid = false;
      }
    }
  };
</script>
