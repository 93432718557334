<template>
  <div>
    <template
      v-if="currentPage"
    >
      <div
        class="container"
      >
        <div class="row">
          <div class="column text-column">
            <h1>{{ currentPage.title }}</h1>
          </div>
        </div>

        <div class="row">
          <div class="column text-column">
            <ParagraphBlock
              :content="currentPage.content"
            />
          </div>
        </div>
      </div>
      <div
        v-if="organisationFormData.length > 0"
        class="container container-fluid bg-blue page-break-after"
      >
        <div
          class="container"
        >
          <ResultOrganisation
            v-if="organisationFormData.length > 0"
          />
        </div>
      </div>
      <ResultProjects
        v-if="projectFormData.length > 0"
        :recommendations="currentPage.recommendations.activities"
      />
      <div
        v-else
        class="container page-break-after"
      >
        <div
          class="row result result-project"
        >
          <div class="column text-column">
            <h2>{{ $t('result.projectsTitle') }}</h2>
            <div
              class="buttons align-left"
            >
              <localized-link
                :to="{ name: 'project' }"
                tag="button"
                class="btn btn-outline"
              >
                {{ $t('button.addProjects') }}
              </localized-link>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container container-fluid bg-blue"
        :class="{ 'page-break-after': strategyFormData.length > 0 }"
      >
        <div
          class="container"
        >
          <ResultStrategy
            v-if="strategyFormData.length > 0"
            :title="currentPage.strategyTitle"
            :content="currentPage.strategyText"
            :recommendations="currentPage.recommendations.maturity"
          />
          <div
            v-else
            class="row result result-strategy result-empty"
          >
            <div class="column text-column">
              <h2>{{ $t('result.strategyTitle') }}</h2>
              <div class="buttons align-left">
                <localized-link
                  :to="{ name: 'strategy' }"
                  tag="button"
                  class="btn btn-outline"
                >
                  {{ $t('button.addStrategy') }}
                </localized-link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="container"
      >
        <div class="row">
          <div class="column text-column">
            <h2>{{ currentPage.nextSectionTitle }}</h2>
            <ParagraphBlock
              :content="currentPage.nextSection"
            />
          </div>
        </div>
        <div class="row">
          <div
            class="column text-column"
          >
            <div class="buttons align-left">
              <localized-link
                to="/"
                tag="button"
                class="btn btn-outline"
              >
                {{ $t('button.refresh') }}
              </localized-link>
              <button
                role="link"
                class="btn"
                @click="generatePDF"
              >
                {{ $t('button.download') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="container container-fluid cta">
        <div class="container">
          <div class="row">
            <div class="column">
              <div class="cta-content">
                <h3>{{ currentPage.ctaTitle }}</h3>
                <ParagraphBlock
                  :content="currentPage.ctaText"
                />
                <a
                  role="button"
                  class="btn"
                  href="mailto:den@den.nl?subject=DEN focusmodel"
                >
                  {{ $t('button.contactDEN') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ParagraphBlock from '@/components/ParagraphBlock.vue';
  import ResultOrganisation from '@/components/ResultOrganisation.vue';
  import ResultStrategy from '@/components/ResultStrategy.vue';
  import ResultProjects from '@/components/ResultProjects.vue';

  export default {
    components: {
      ParagraphBlock,
      ResultOrganisation,
      ResultStrategy,
      ResultProjects
    },

    computed: {
      currentPage() {
        if (!this.pageContent) return;
        return this.pageContent['result'];
      },

      ...mapState(['pageContent', 'projectFormData', 'projectExtraFormData', 'strategyFormData', 'organisationFormData'])
    },

    created() {
      if (this.pageContent.length === 0) {
        this.$store.dispatch('getContent', this.$i18n.locale);
      }
      if (this.projectFormData.length === 0 && this.strategyFormData.length === 0 && this.organisationFormData.length === 0) {
        this.$router.push({ path: '/' }).catch(() => {});
      }
    },

    methods: {
      generatePDF() {
        // this.$refs.html2Pdf.generatePdf();
        this.$gtag.event('download', { method: 'Download PDF' });
        window.print();
      }
    },

    metaInfo() {
      return {
        title: this.$t('seo.title.result')
      };
    }
  };
</script>
