<template>
  <div
    class="row result result-strategy"
  >
    <div
      class="column text-column"
    >
      <h2>{{ $t('result.strategyTitle') }}</h2>
      <p
        v-for="question in strategyFormData"
        :key="question.question"
      >
        <strong>{{ question.questionText }}</strong><br>
        <span>
          {{ getAnswer(question.answer) }}
        </span>
      </p>
      <h3>{{ title }}</h3>
      <ParagraphBlock
        :content="scoreResultText[0]"
      />
      <ParagraphBlock
        v-if="recommendationText"
        :content="recommendationText"
      />
      <ParagraphBlock
        :content="scoreResultText[1]"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ParagraphBlock from '@/components/ParagraphBlock.vue';

  export default {
    name: 'ResultStrategy',

    components: {
      ParagraphBlock
    },

    props: {
      print: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        required: true
      },
      content: {
        type: Array,
        required: true
      },
      recommendations: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        maxTotalScore: 75.5
      };
    },

    computed: {
      totalScore() {
        return Math.round(this.strategyTotalScore * 100 / this.maxTotalScore);
      },

      scoreLabel() {
        if (this.totalScore < 20) {
          return 'passive';
        } else if (this.totalScore >= 21 && this.totalScore < 40) {
          return 'incidental';
        } else if (this.totalScore >= 41 && this.totalScore < 60) {
          return 'experimental';
        } else if (this.totalScore >= 61 && this.totalScore < 80) {
          return 'partial';
        } else {
          return 'integrated';
        }
      },

      scoreInText() {
        return this.$t(`result.scoreLabels.${this.scoreLabel}`);
      },

      scoreResultText() {
        const result = this.content.map(paragraph => {
          return paragraph.replace('{{{score}}}', `<strong>${this.scoreInText}</strong>`);
        });
        return result;
      },

      recommendationText() {
        if (!this.recommendations || this.recommendations.length === 0) return [];
        const recommendationText = this.recommendations[this.scoreLabel];

        return recommendationText.text;
      },

      ...mapState(['strategyFormData', 'strategyTotalScore', 'adviceStrategy'])
    },

    created() {
      if (!this.print && !this.adviceStrategy) {
        this.$store.commit('hasShownAdviceStrategy');

        if (this.$gtag) {
          this.$gtag.event('Advies', {
            'event_category': 'Maturiteit',
            'event_label': this.scoreInText
          });
        }
      }
    },

    methods: {
      getAnswer(answer) {
        if (!answer) return '-';
        if (answer.radio) {
          if (answer.other && answer.other !== null) {
            return `${answer.radio.value} ${answer.other}`;
          }
          if (answer.more && answer.more !== null) {
            return `${answer.radio.value} ${answer.more}`;
          }
          return answer.radio.value;
        } else if (answer.checkbox) {
          let checked = [];
          for (const c of answer.checkbox) {
            if (c.key === 'other' && answer.other !== null) {
              checked.push(`${c.value} ${answer.other}`);
            } else {
              checked.push(c.value);
            }
          }
          return checked.join(', ');
        } else if (!answer.radio && !answer.checkbox && answer.other) {
          return answer.other;
        }
        return answer;
      }
    }
  };
</script>
