<template>
  <div>
    <div
      class="container page-break-after"
    >
      <div
        class="row result result-project"
      >
        <div class="column image-column">
          <FocusQuadrant
            v-if="projectFormData.length > 0"
            :coordinates="projectCoordinates"
            :selected="projectSelectedQuadrant"
            :active-project="activeProject"
          />
        </div>
        <div class="column text-column">
          <h2>{{ $t('result.projectsTitle') }}</h2>
          <div class="project-table-wrapper">
            <table
              class="project-table"
            >
              <tr>
                <th>
                  {{ $t('result.projectTable.headings.projectName') }}
                </th>
                <th
                  class="sort"
                  :title="$t('result.projectTable.headings.pace')"
                  @click="sortColumn('pace')"
                >
                  {{ $t('result.projectTable.headings.pace') }}
                  <span
                    v-if="projectFormData.length > 1 "
                    class="arrow"
                  />
                </th>
                <th
                  class="sort"
                  :title="$t('result.projectTable.headings.involvement')"
                  @click="sortColumn('percentageEmployees')"
                >
                  {{ $t('result.projectTable.headings.involvement') }}
                  <span
                    v-if="projectFormData.length > 1 "
                    class="arrow"
                  />
                </th>
                <th
                  class="sort"
                  :title="$t('result.projectTable.headings.successfulGoal')"
                  @click="sortColumn('successGoal')"
                >
                  {{ $t('result.projectTable.headings.successfulGoal') }}
                  <span
                    v-if="projectFormData.length > 1 "
                    class="arrow"
                  />
                </th>
                <th
                  class="sort"
                  :title="$t('result.projectTable.headings.substantial')"
                  @click="sortColumn('substantial')"
                >
                  {{ $t('result.projectTable.headings.substantial') }}
                  <span
                    v-if="projectFormData.length > 1 "
                    class="arrow"
                  />
                </th>
              </tr>
              <tbody
                v-for="(project, index) in projectFormData"
                :key="index"
                :class="{ 'active': activeProject === index }"
              >
                <tr
                  @click="toggleProject(index)"
                >
                  <td
                    v-for="question in projectTableData(project)"
                    :key="question.question"
                    :class="{ 'score': getScore(question.answer) }"
                  >
                    <template v-if="getScore(question.answer)">
                      <span
                        v-for="dot in 4"
                        :key="dot"
                        class="dot"
                        :class="{ 'dot-active': dot <= getScore(question.answer) }"
                      />
                    </template>
                    <div
                      v-else
                      class="project-name-wrapper"
                    >
                      <span
                        class="project-name"
                        :title="getAnswer(question.answer) !== '-' ? getAnswer(question.answer) : ''"
                      >
                        {{ getAnswer(question.answer) }}
                      </span>
                      <span
                        v-if="getAnswer(question.answer) !== '-'"
                        class="plus"
                      />
                    </div>
                  </td>
                </tr>
                <tr class="details">
                  <td colspan="5">
                    <ParagraphBlock
                      v-for="question in projectTableExtraData(project)"
                      :key="question.question"
                      :content="question"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="buttons">
            <button
              class="btn btn-secondary btn-small"
              @click="addProject"
            >
              {{ $t('button.addProject') }}
            </button>
          </div>
          <ParagraphBlock
            v-if="recommendationText"
            :title="recommendationText.length > 0 ? $t('result.projectsRecommendationTitle') : false"
            :content="recommendationText"
          />
          <LinkBlock
            v-if="recommendationLinks && recommendationLinks.length > 0 && $i18n.locale === 'nl'"
            :title="$t('result.readMore')"
            :links="recommendationLinks"
          />
        </div>
      </div>
    </div>
    <div
      v-for="(project, index) in projectFormData"
      :key="index"
      class="container page-break-after"
    >
      <div
        class="row result result-project result-print-only"
      >
        <div class="column image-column">
          <FocusQuadrant
            :coordinates="projectCoordinates.slice(index, index + 1)"
            :selected="projectSelectedQuadrant.slice(index, index + 1)"
          />
        </div>
        <div class="column text-column">
          <ParagraphBlock
            v-for="question in projectTableExtraData(project)"
            :key="question.question"
            :content="question"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import FocusQuadrant from '@/components/FocusQuadrant.vue';
  import ParagraphBlock from '@/components/ParagraphBlock.vue';
  import LinkBlock from '@/components/LinkBlock.vue';

  export default {
    name: 'ResultProjects',

    components: {
      FocusQuadrant,
      ParagraphBlock,
      LinkBlock
    },

    props: {
      print: {
        type: Boolean,
        default: false
      },
      recommendations: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        activeProject: null,
        sorting: null,
        sortOrderAsc: true
      };
    },

    computed: {
      projectSelectedQuadrant() {
        if (this.projectFormData.length === 0) return;
        let selectedQuadrants = [];
        for (const project of this.projectFormData) {
          const projectFiltered = project.filter(Boolean);
          selectedQuadrants.push(projectFiltered.find(({ questionKey }) => questionKey === 'quadrant').answer.radio.key);
        }
        if (selectedQuadrants.length === 0) return;
        return selectedQuadrants;
      },

      projectCoordinates() {
        if (this.projectFormData.length === 0) return;
        const allProjectsCoordinates = [];
        for (const project of this.projectFormData) {
          const projectFiltered = project.filter(Boolean);
          const sliders = projectFiltered.filter(({ questionKey }) => questionKey.startsWith('slider'));
          const coordinates = [];
          coordinates.push([0, 0]); // start position
          for (const slider of sliders) {
            coordinates.push(slider.answer);
          }
          allProjectsCoordinates.push(coordinates);
        }
        return allProjectsCoordinates;
      },

      focusQuadrantTotalScore() {
        if (this.projectExtraFormData.length === 0) return;
        if (this.projectExtraFormData[0].length === 0 && this.projectFormData.length === 0) return;

        let score = {};
        const scores = [50, 25, 15, 10];
        // basic score
        const focusQuadrantAnswer = this.projectExtraFormData[0].answer.sort;
        for (let i = 0; i < focusQuadrantAnswer.length; i++) {
          score[focusQuadrantAnswer[i].key] = scores[i];
        }

        return score;
      },

      focusQuadrant() {
        const score = this.focusQuadrantTotalScore;
        if (!score) return;

        const sorted = Object.entries(score)
          .sort((a, b) => b[1] - a[1])
          .reduce((a, c) => (a[c[0]] = c[1], a), {});

        return Object.keys(sorted)[0];
      },

      focusQuadrantPlusProjectsScore() {
        let score = this.focusQuadrantTotalScore;
        if (!score) return;

        // plus score based on projects
        for (const project of this.projectFormData) {
          const projectQuadrant = project.find(({ questionKey }) => questionKey === 'quadrant').answer.radio.key;
          score[projectQuadrant] += 50;
        }

        const sorted = Object.entries(score)
          .sort((a, b) => b[1] - a[1])
          .reduce((a, c) => (a[c[0]] = c[1], a), {});

        // let arr = Object.values(score);
        // let max = Math.max(...arr);

        return Object.keys(sorted)[0];
      },

      adviceBasedOnActivities() {
        return this.focusQuadrant + '-' + this.focusQuadrantPlusProjectsScore;
      },

      expandQuadrant() {
        if (!this.projectExtraFormData[1] || this.projectExtraFormData[1].answer === null) {
          return false;
        }

        return this.projectExtraFormData[1].answer.radio.key;
      },

      recommendationText() {
        if (!this.recommendations || this.recommendations.length === 0) return [];
        if (!this.focusQuadrantPlusProjectsScore || !this.expandQuadrant) return [];
        const recommendation = `${this.focusQuadrantPlusProjectsScore}-${this.expandQuadrant}`;
        return this.recommendations[recommendation].text;
      },

      recommendationLinks() {
        if (!this.recommendations || this.recommendations.length === 0) return [];
        if (!this.focusQuadrantPlusProjectsScore || !this.expandQuadrant) return [];

        const recommendation = `${this.focusQuadrantPlusProjectsScore}-${this.expandQuadrant}`;
        return this.recommendations[recommendation].link;
      },

      ...mapState(['projectFormData', 'projectExtraFormData', 'adviceProjects'])
    },

    created() {
      if (!this.print && !this.adviceProjects) {
        const focus = this.$t(`quadrant.labels.${this.focusQuadrantPlusProjectsScore}`);
        const expand = this.$t(`quadrant.labels.${this.expandQuadrant}`);

        this.$store.commit('hasShownAdviceProjects');

        if (this.$gtag) {
          this.$gtag.event('Advies', {
            'event_category': 'Activiteiten',
            'event_label': `${focus}-${expand}`
          });
        }
      }
    },

    methods: {
      addProject() {
        this.$store.commit('createProject');
        this.$router.push({ name: 'project' }).catch(() => {});
      },

      toggleProject(index) {
        if (this.activeProject === index) {
          this.activeProject = null;
        } else {
          this.activeProject = index;
        }
      },

      projectTableData(project) {
        const include = ['projectName', 'pace', 'percentageEmployees', 'successGoal', 'substantial'];
        const projectFiltered = project.filter(Boolean);
        const filtered = projectFiltered.filter(({ questionKey }) => include.includes(questionKey));
        return filtered;
      },

      projectTableExtraData(project) {
        const include = ['projectName', 'projectDescription', 'projectExplanation'];
        const data = [];
        const projectFiltered = project.filter(Boolean);
        const filtered = projectFiltered.filter(({ questionKey }) => include.includes(questionKey));
        // TODO: rewrite?
        for (const project of filtered) {
          const answer = project.answer ? project.answer : '-';
          data.push([`<strong>${project.questionText}</strong><br>${answer}`]);
        }
        return data;
      },

      sortColumn(sort) {
        // TODO: double check to see if this works
        if (this.sorting === sort) {
          this.sortOrderAsc = !this.sortOrderAsc;
        } else {
          this.sortOrderAsc = true;
        }
        this.projectFormData.sort((a, b) => {
          const compareA = a.filter(({ questionKey }) => questionKey === sort)[0].answer.radio.score;
          const compareB = b.filter(({ questionKey }) => questionKey === sort)[0].answer.radio.score;
          if (compareA > compareB) return this.sortOrderAsc ? 1 : -1;
          if (compareA < compareB) return this.sortOrderAsc ? -1 : 1;
          return 0;
        });
        this.sorting = sort;
      },

      getScore(answer) {
        if (!answer || !answer.radio) return;
        return answer.radio.score;
      },

      getAnswer(answer) {
        if (!answer) return '-';
        if (answer.radio) {
          if (answer.other && answer.other !== null) {
            return `${answer.radio.value} ${answer.other}`;
          }
          return answer.radio.value;
        } else if (answer.checkbox) {
          let checked = [];
          for (const c of answer.checkbox) {
            if (c.key === 'other' && answer.other !== null) {
              checked.push(`${c.value} ${answer.other}`);
            } else {
              checked.push(c.value);
            }
          }
          return checked.join(', ');
        }
        return answer;
      }
    }
  };
</script>
